import { configureStore } from "@reduxjs/toolkit";

import utilReducer from "./reducers/util";


const store = configureStore({
  reducer: {
    util: utilReducer
  },
});

export default store;

import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { getNewsInfo } from "../controller/publishingController";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const News = () => {
  const param = useParams();

  const [data, setData] = useState({
    title: "",
    content: ""
  });

  const callGetNews = async () => {
    try {
      let res = await getNewsInfo(param["slug"]);
      if (res.data.success === true) {
        setData(res.data.data[0]);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callGetNews();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "#f5f5f5",
        paddingBottom: 10,
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <Grid xs={12}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={10}
          pb={4}
          pl={10}
          pr={10}
        >
          <Typography variant="h1" sx={{fontSize:"24px !important"}}>{data.title}</Typography>
        </Stack>
      </Grid>
      <Grid xs={3}></Grid>
      <Grid xs={6}>
        <Stack direction="column" spacing={2} pb={3}>
          {parse(data.content)}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default News;

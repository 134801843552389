import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Core from "./pages/Core";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
                <Core />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;

import axios from "./axios";

export const getAllFeatured = async function () {
  return await axios.get("/v1/publishing/getAllFeatured");
};
export const getLatestBooks = async function () {
  return await axios.get("/v1/publishing/getLatestBooks");
};
export const getAllComingSoon = async function () {
  return await axios.get("/v1/publishing/getAllComingSoon");
};
export const getAllPublishedBookByGenreTakeFive = async function (genre) {
  return await axios.get("/v1/publishing/getAllPublishedBookByGenreTakeFive/"+genre);
};
export const getAllBooksByGenre = async function (genre) {
  return await axios.get("/v1/publishing/getAllBooksByGenre/"+genre);
};
export const getAllComics = async function () {
  return await axios.get("/v1/publishing/getAllBooksComic");
};
export const getBookInfo = async function (slug,id) {
  return await axios.post("/v1/publishing/getBookInfo",{"slug":slug,"id":id});
};
export const getAllAuthors = async function () {
  return await axios.get("/v1/publishing/getAllAuthors");
};
export const getAuthorInfo = async function (slug) {
  return await axios.post("/v1/publishing/getAuthorInfo",{"slug":slug});
};
export const getLastNews = async function () {
  return await axios.get("/v1/publishing/getLastNews");
};
export const getNewsInfo = async function (slug) {
  return await axios.post("/v1/publishing/getNewsInfo",{"slug":slug});
};
export const getBookInfoSimilar = async function (id) {
  return await axios.post("/v1/publishing/getBookInfoSimilar",{"id":id});
};

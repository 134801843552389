import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  getAllFeatured,
  getLatestBooks,
  getAllComingSoon,
  getLastNews,
} from "../controller/publishingController";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [feature, setFeature] = useState([]);
  const [latest, setLatest] = useState([]);
  const [soon, setSoon] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [lastNewsRes, featuredRes, latestRes, soonRes] =
          await Promise.all([
            getLastNews(),
            getAllFeatured(),
            getLatestBooks(),
            getAllComingSoon(),
          ]);

        if (lastNewsRes.data.success === true) {
          setNews(lastNewsRes.data.data);
        } else {
          throw new Error("Failed to fetch last news");
        }

        if (featuredRes.data.success === true) {
          setFeature(featuredRes.data.data);
        } else {
          throw new Error("Failed to fetch featured content");
        }

        if (latestRes.data.success === true) {
          setLatest(latestRes.data.data);
        } else {
          throw new Error("Failed to fetch latest books");
        }

        if (soonRes.data.success === true) {
          setSoon(soonRes.data.data);
        } else {
          throw new Error("Failed to fetch coming soon content");
        }
      } catch (error) {
        //console.log(error);
      }
    };

    fetchAllData();
  }, []);

  const viewBook = (list, key) => {
    if (latest[key] !== undefined) {
      if (list == "latest") {
        return "book/" + latest[key].slug + "/" + latest[key].seriesID;
      }
      if (list == "featured") {
        return "book/" + feature[key].slug + "/" + feature[key].seriesID;
      }
      if (list == "soon") {
        return "book/" + soon[key].slug + "/" + soon[key].seriesID;
      }
    }
  };

  const saveData = (data) => {
    navigate("/news/" + data.slug);
  };

  return (
    <>
      <Box
        sx={{
          aspectRation: "auto 4000 / 800",
          width: "auto",
          height: "400px",
          marginBottom: -1,
          backgroundPosition: "center center",
          backgroundImage: 'url("/img/MQBannerWeb.webp")',
          backgroundSize: "cover",
          maxWidth: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          pt={15}
        ></Stack>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#f5f5f5",
          paddingBottom: 10,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={10}
          >
            <Box sx={{ pb: 5 }}>
              <Typography variant="h2">Featured Titles</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          xl={1.75}
          sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
        ></Grid>
        {feature.map((data, key) => (
          <Grid key={data.slug} xs={6} xl={1.75}>
            <Link to={viewBook("featured", key)}>
              <Box>
                <img
                  href="/"
                  src={
                    "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                    data.slug +
                    ".png"
                  }
                  alt="image"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Link>
          </Grid>
        ))}
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={10}
          >
            <Box sx={{ pb: 5 }}>
              <Typography variant="h2">Latest Releases</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          xl={1.75}
          sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
        ></Grid>

        {latest.map((data, key) => (
          <Grid key={data.slug} xs={6} xl={1.75}>
            <Link to={viewBook("latest", key)}>
              <Box>
                <img
                  loading="lazy"
                  href="/"
                  src={
                    "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                    data.slug +
                    ".png"
                  }
                  alt="image"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Link>
          </Grid>
        ))}
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={10}
          >
            <Box sx={{ pb: 5 }}>
              <Typography variant="h2">Coming Soon</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          xl={1.75}
          sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
        ></Grid>
        {soon.map((data, key) => (
          <Grid key={data.slug} xs={6} xl={1.75}>
            <Link to={viewBook("soon", key)}>
              <Box>
                <img
                  loading="lazy"
                  href="/"
                  src={
                    "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                    data.slug +
                    ".png"
                  }
                  alt="image"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Link>
          </Grid>
        ))}
        <Grid
          xs={12}
          sx={{
            width: "100%",
            marginBottom: -1,
            marginTop: 10,
            backgroundImage: 'url("/img/backNews.webp")',
            backgroundSize: "cover",
            backgroundPosition: "auto",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              pt: { xs: 5, sm: 8, md: 10 },
              pb: { xs: 5, sm: 8, md: 10 },
              pl: { xs: 2, sm: 5, md: 10 },
              pr: { xs: 2, sm: 5, md: 10 },
            }}
          >
            <Box>
              <Typography variant="h4" textAlign="center">
                Subscribe to the MoonQuill Newsletter and get 4 E-books for
                FREE,
                <br />
                as well as news on our latest releases, giveaways, and more!
              </Typography>
            </Box>
            <Box
              sx={{ width: { xs: "90%", sm: "70%", md: "50%" }, pt: 2 }}
              component="form"
              noValidate
              autoComplete="off"
              textAlign="center"
            >
              <Button
                sx={{ ml: { xs: 0, sm: 2, md: 5 } }}
                variant="contained"
                size="large"
                href="https://subscribepage.io/moonquill"
              >
                Subscribe to the MoonQuill Newsletter
              </Button>
            </Box>
          </Stack>
        </Grid>

        <Grid xs={12}>
          <Stack
            id="newsletter"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={10}
          >
            <Box sx={{ pb: 5 }}>
              <Typography variant="h2">Latest News</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          xl={1.75}
          sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
        ></Grid>
        {news.map((data, key) => (
          <Grid key={data.slug} xs={12} xl={2.91}>
            <Box>
              <img
                loading="lazy"
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/news/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "300px" }}
              />
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Box sx={{ pb: 0 }}>
                  <Typography variant="h5">{data.title}</Typography>
                </Box>
                <Button
                  sx={{ ml: 0 }}
                  variant="contained"
                  size="large"
                  onClick={() => saveData(data)}
                >
                  Read More
                </Button>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { getAllComics } from "../controller/publishingController";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";

const Comics = () => {
  const [listOfBooks, setBooks] = useState([]);
  useEffect(() => {
    grabBooks();
  }, []);

  const grabBooks = async () => {
    try {
      let res = await getAllComics();
      if (res.data.success === true) {
        setBooks(res.data.data);
        res.data.data.map((data) => {
          console.log(data);
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "#f5f5f5",
        paddingBottom: 10,
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <Grid xs={12}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={10}
          pb={3}
        >
          <Typography variant="h1">Comics</Typography>
        </Stack>
      </Grid>
      {listOfBooks.map((b, index) => (
        <>
          {index === 0 ? <Grid key={"zero" + index} xs={1}></Grid> : null}
          <Grid key={b.slug} xs={12} xl={5}>
            <Link to={b.linkComic}>
              <Box>
                <img
                  href="/"
                  src={
                    "https://storage.googleapis.com/moonquill-core.appspot.com/published-comics/" +
                    b.slug +
                    ".png"
                  }
                  alt="image"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Link>
          </Grid>
          {index % 2 === 1 ? <Grid key={"zero" + index} xs={1}></Grid> : null}
          {index % 2 === 1 ? <Grid key={"zero" + index} xs={1}></Grid> : null}
        </>
      ))}
    </Grid>
  );
};

export default Comics;

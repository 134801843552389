import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {
  getBookInfo,
  getBookInfoSimilar,
} from "../controller/publishingController";
import Link from "@mui/material/Link";

const BookDetail = ({}) => {
  const param = useParams();

  const [bookData, setBookData] = useState(null);
  const [similarData, setSimilarData] = useState(null);

  const callGetBookInfo = async () => {
    try {
      let res = await getBookInfo(param["slug"], param["id"]);
      if (res.data.success === true) {
        setBookData(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const callGetBookSimilar = async () => {
    try {
      let res = await getBookInfoSimilar(param["id"]);
      if (res.data.success === true) {
        setSimilarData(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callGetBookInfo();
    callGetBookSimilar();
  }, []);

  const returnBook = () => {
    if (bookData === null) {
      return null;
    }
    return (
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#f5f5f5",
          paddingBottom: 10,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Grid xs={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={10}
            pb={{ xl: 10 }}
          >
            <Typography variant="h1">{bookData.name}</Typography>
            <Typography variant="h2">
              {bookData.seriesName}
              {", "}
              {bookData.num}
            </Typography>
            <Typography variant="h3">
              by {bookData.authorName}
              {bookData.authorName2 ? " & " + bookData.authorName2 : ""}
              {bookData.authorName3 ? " & " + bookData.authorName3 : ""}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={3}></Grid>
        <Grid xs={12} xl={2}>
          <Box>
            <img
              href="/"
              src={
                "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                param["slug"] +
                ".png"
              }
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
          <Button
            href={bookData.linkAmazon}
            sx={{ ml: 0, mt: 2, width: "100%" }}
            variant="contained"
            size="large"
          >
            Buy Book
          </Button>
          {bookData.linkAudio !== "" ? (
            <Button
              href={bookData.linkAudio}
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
            >
              Buy AudioBook
            </Button>
          ) : null}
        </Grid>

        <Grid xs={12} xl={4}>
          <Box sx={{ marginLeft: { xl: 10 } }}>
            <Typography variant="h5" component="div" whiteSpace="pre-line">
              {bookData.description}
            </Typography>
          </Box>
        </Grid>
        {bookData.seriesBooks.length === 0 ? null : (
          <>
            <Grid key={123} xs={12}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                pt={10}
              >
                <Box sx={{ pb: 5 }}>
                  <Typography variant="h2">
                    {bookData.seriesName} Series Books
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            {bookData.seriesBooks.map((book, key) => (
              <>
                {key === 0 ? (
                  <Grid
                    xl={1.75}
                    sx={{
                      display: { xs: "none", xl: "block" },
                      xlOffset: 1.75,
                    }}
                  ></Grid>
                ) : null}
                <Grid key={key} xs={6} xl={1.75}>
                  <Link href={"/book/" + book.slug + "/" + book.seriesID}>
                    <Box>
                      <img
                        href="/"
                        src={
                          "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                          book.slug +
                          ".png"
                        }
                        alt="image"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Box>
                  </Link>
                </Grid>
                {key % 5 === 1 ? (
                  <Grid
                    xl={1.75}
                    sx={{
                      display: { xs: "none", xl: "block" },
                      xlOffset: 1.75,
                    }}
                  ></Grid>
                ) : null}
              </>
            ))}
          </>
        )}

        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={10}
          >
            <Box sx={{ pb: 5 }}>
              <Typography variant="h2" fontSize={{ xs: "1.5rem" }}>
                OTHER BOOKS YOU MIGHT LIKE
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          xl={1.75}
          sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
        ></Grid>
        <Grid xs={6} xl={1.75}>
          <Box>
            <img
              href="/"
              src="/img/cover/1.jpg"
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid xs={6} xl={1.75}>
          <Box>
            <img
              href="/"
              src="/img/cover/2.jpg"
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid xs={6} xl={1.75}>
          <Box>
            <img
              href="/"
              src="/img/cover/3.jpg"
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid xs={6} xl={1.75}>
          <Box>
            <img
              href="/"
              src="/img/cover/5.jpg"
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid xs={6} xl={1.75}>
          <Box>
            <img
              href="/"
              src="/img/cover/6.jpg"
              alt="image"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return returnBook();
};

const mapStateToProps = (state) => ({
  bookData: state.util.bookData,
});

export default connect(mapStateToProps, null)(BookDetail);

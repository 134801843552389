import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { getAllBooksByGenre } from "../controller/publishingController";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
const Genre = () => {
  const param = useParams();
  const [name, setName] = useState("");
  const [listOfBooks, setBooks] = useState(null);
  useEffect(() => {
    if (param["genre"] === "fantasy") {
      setName("Fantasy");
      grabBooks("Fantasy");
    }
    if (param["genre"] === "light-novels") {
      setName("Light Novels");
      grabBooks("Light Novels");
    }
    if (param["genre"] === "gamelit-litrpg") {
      setName("GameLit / LitRPG");
      grabBooks("GameLitRPG");
    }
    if (param["genre"] === "eastern-fantasy") {
      setName("Eastern Fantasy");
      grabBooks("Eastern Fantasy");
    }
    if (param["genre"] === "soon") {
      setName("Upcoming Releases");
      grabBooks("soon");
    }

    
  }, []);

  const grabBooks = async (genre) => {
    try {
      let res = await getAllBooksByGenre(genre);
      if (res.data.success === true) {
        setBooks(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewBook = (slug, serie) => {
    return "/book/" + slug + "/" + serie;
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "#f5f5f5",
        paddingBottom: 10,
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <Grid xs={3}></Grid>
      <Grid xl={6} xs={12}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={10}
          pb={3}
        >
          <Typography variant="h1">{name} Books</Typography>
        </Stack>
      </Grid>

      {listOfBooks === null ? null : (
        <>
          {listOfBooks.map((data) => (
            <>
              {data.books === undefined ? null : (
                <>
                  <Grid xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      pt={3}
                    >
                      <Box sx={{ pb: 5 }}>
                        <Typography variant="h2">{data.name}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid
                    xl={1.75}
                    sx={{
                      display: { xs: "none", xl: "block" },
                      xlOffset: 1.75,
                    }}
                  ></Grid>

                  {data.books.map((b, key) => (
                    <Grid key={b.slug} xs={6} xl={1.75}>
                      <Link to={viewBook(b.slug, b.seriesID)}>
                        <Box>
                          <img
                            href="/"
                            src={
                              "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                              b.slug +
                              ".png"
                            }
                            alt="image"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Box>
                      </Link>
                    </Grid>
                  ))}
                </>
              )}
            </>
          ))}
        </>
      )}
    </Grid>
  );
};

export default Genre;

import axios from "axios";

//https://api.moonquill.com

//http://10.0.2.2:5001/moonquill-core/us-central1/app
//const url = "http://localhost:5001/moonquill-core/us-central1/app"
const url = "https://api.moonquill.com"
const instance = axios.create({
  baseURL: url ,
});

export default instance;
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Header from "../components/appbar/Header";
import Toolbar from "@mui/material/Toolbar";
import Footer from "../components/stack/Footer";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import BookDetail from "./BookDetail";
//import ComicDetail from "./ComicDetail";
import Catalog from "./Catalog";
import Genre from "./Genre";
//import AuthorDetail from "./AuthorDetail";
import Comics from "./Comics";
//import Authors from "./Authors";
import News from "./News";

const Main = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Header />
      <Box sx={{ flexDirection: "column", width: "100%" }}>
        <Toolbar></Toolbar>
        <Routes>
          <Route path="*" element={<Dashboard />} />
          <Route path="/book/:slug/:id" element={<BookDetail />} />
          {/*  <!--<Route path="/comic/:slug/:id" element={<ComicDetail />} /> */}
          <Route path="/genre/:genre" element={<Genre />} />
          <Route path="/comics" element={<Comics />} />
          {/*<Route path="/author/:slug" element={<AuthorDetail />} />*/}
          {/*<Route path="/authors" element={<Authors />} />*/}
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/news/:slug" element={<News />} />
        </Routes>
        <Container sx={{ backgroundColor: "white" }} maxWidth={false}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default Main;

import * as ActionTypes from "../actions";

const initialState = {
  bookData: null,
  newsData: null,
};

const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.SET_BOOK_DATA) {
    return {
      ...state,
      bookData: action.data,
    };
  }
  if (action.type === ActionTypes.SET_NEWS_DATA) {
    return {
      ...state,
      newsData: action.data,
    };
  }

  return { ...state };
};

export default reducer;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { getAllPublishedBookByGenreTakeFive } from "../controller/publishingController";
import { Link } from "react-router-dom";

const Catalog = () => {
  const [lightnovel, setLightnovel] = useState([]);
  const [fantasy, setFantasy] = useState([]);
  const [eastern, setEastern] = useState([]);
  const [gamelit, setGamelit] = useState([]);
  const [other, setOther] = useState([]);
  const [soon, setSoon] = useState([]);
  const [scifi, setScifi] = useState([]);

  //Cultivation, Fantasy, Gamelit/LitRPG, Light Novel, Sci-Fi, Other

  useEffect(() => {
    const fetchAllGenres = async () => {
      const genres = [
        "Light Novels",
        "Fantasy",
        "Eastern Fantasy",
        "GameLitRPG",
        "Sci-Fi",
        "Other",
        "Soon",
      ];

      try {
        // Create an array of promises for all genres
        const promises = genres.map((genre) => callFiveLightNovel(genre));

        // Wait for all promises to resolve
        await Promise.all(promises);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAllGenres();
  }, []);

  const parseBook = async (data, genre) => {
    if (data.length !== 0) {
      let newData = [];
      data.forEach((serie) => {
        if (serie.books !== undefined) {
          if (serie.books.length === 0) {
            return;
          }
          newData.push(serie.books[serie.books.length - 1]);
        }
      });
      if (genre === "Light Novels") {
        setLightnovel(newData);
      } else if (genre === "Fantasy") {
        setFantasy(newData);
      } else if (genre === "Eastern Fantasy") {
        setEastern(newData);
      } else if (genre === "GameLitRPG") {
        setGamelit(newData);
      } else if (genre === "Sci-Fi") {
        setScifi(newData);
      } else if (genre === "Soon") {
        setSoon(data);
      } else {
        setOther(newData);
      }
    }
  };
  const viewBook = (list, key) => {
    //console.log(list);
    if (list == "fantasy") {
      return "/book/" + fantasy[key].slug + "/" + fantasy[key].seriesID;
    }
    if (list == "gamelit") {
      return "/book/" + gamelit[key].slug + "/" + gamelit[key].seriesID;
    }
    if (list == "eastern") {
      return "/book/" + eastern[key].slug + "/" + eastern[key].seriesID;
    }
    if (list == "lightnovel") {
      return "/book/" + lightnovel[key].slug + "/" + lightnovel[key].seriesID;
    }
    if (list == "sci-fi") {
      return "/book/" + scifi[key].slug + "/" + scifi[key].seriesID;
    }
    if (list == "other") {
      return "/book/" + other[key].slug + "/" + other[key].seriesID;
    }
    if (list == "soon") {
      return "/book/" + soon[key].slug + "/" + soon[key].seriesID;
    }
  };
  const callFiveLightNovel = async (genre) => {
    try {
      let res = await getAllPublishedBookByGenreTakeFive(genre);
      if (res.data.success === true) {
        //console.log(res.data);
        parseBook(res.data.data, genre);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "#f5f5f5",
        paddingBottom: 10,
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <Grid item xs={12} sm={6} md={3}></Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={10}
        >
          <Typography variant="h1">Our Catalog</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center" spacing={2} pt={3} pb={3}>
          <Typography variant="h2">Browse by Category</Typography>

          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={2}
            mt={2}
            gap={2}
          >
            <Button
              sx={{ mt: 2, mb: 2, p: 2, fontSize: "1.5rem !important" }}
              variant="contained"
              href="/genre/soon"
            >
              Coming Soon
            </Button>
            <Button
              sx={{ mt: 2, mb: 2, p: 2, fontSize: "1.5rem !important" }}
              variant="contained"
              href="/genre/fantasy"
            >
              Fantasy
            </Button>
            <Button
              sx={{ mt: 2, mb: 2, p: 2, fontSize: "1.5rem !important" }}
              variant="contained"
              href="/genre/gamelit-litrpg"
            >
              GameLit / LitRPG
            </Button>
            <Button
              sx={{ mt: 2, mb: 2, p: 2, fontSize: "1.5rem !important" }}
              variant="contained"
              href="/genre/eastern-fantasy"
            >
              Eastern Fantasy
            </Button>
            <Button
              sx={{ mt: 2, mb: 2, p: 2, fontSize: "1.5rem !important" }}
              variant="contained"
              href="/genre/light-novels"
            >
              Light Novels
            </Button>

            <Button
              sx={{ mt: 2, mb: 2, p: 2, fontSize: "1.5rem !important" }}
              variant="contained"
              href="/genre/other"
            >
              Other
            </Button>
          </Stack>
        </Stack>
      </Grid>

      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={3}
        >
          <Box sx={{ pb: 5, textAlign: "center" }}>
            <Typography variant="h2">Coming Soon</Typography>
            <Button
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
              href="/genre/soon"
            >
              <Typography variant="h5">View All Coming Soon Series</Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        xl={1.75}
        sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
      ></Grid>
      {soon.map((data, key) => (
        <Grid key={data.slug} xs={6} xl={1.75}>
          <Link to={viewBook("soon", key)}>
            <Box>
              <img
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Link>
        </Grid>
      ))}
      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={3}
        >
          <Box sx={{ pb: 5, textAlign: "center" }}>
            <Typography variant="h2">Fantasy</Typography>
            <Button
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
              href="/genre/fantasy"
            >
              <Typography variant="h5">View All Fantasy Series</Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        xl={1.75}
        sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
      ></Grid>
      {fantasy.map((data, key) => (
        <Grid key={data.slug} xs={6} xl={1.75}>
          <Link to={viewBook("fantasy", key)}>
            <Box>
              <img
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Link>
        </Grid>
      ))}

      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={10}
        >
          <Box sx={{ pb: 5, textAlign: "center" }}>
            <Typography variant="h2">GameLit/LitRPG</Typography>
            <Button
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
              href="/genre/gamelit-litrpg"
            >
              <Typography variant="h5">
                View All GameLit/LitRPG Series
              </Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        xl={1.75}
        sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
      ></Grid>
      {gamelit.map((data, key) => (
        <Grid key={data.slug} xs={6} xl={1.75}>
          <Link to={viewBook("gamelit", key)}>
            <Box>
              <img
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Link>
        </Grid>
      ))}
      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={3}
        >
          <Box sx={{ pb: 5, textAlign: "center" }}>
            <Typography variant="h2">Eastern Fantasy</Typography>
            <Button
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
              href="/genre/eastern-fantasy"
            >
              <Typography variant="h5">
                View All Eastern Fantasy Series
              </Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        xl={1.75}
        sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
      ></Grid>
      {eastern.map((data, key) => (
        <Grid key={data.slug} xs={6} xl={1.75}>
          <Link to={viewBook("eastern", key)}>
            <Box>
              <img
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Link>
        </Grid>
      ))}
      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={3}
        >
          <Box sx={{ pb: 5, textAlign: "center" }}>
            <Typography variant="h2">Light Novels</Typography>
            <Button
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
              href="/genre/light-novels"
            >
              <Typography variant="h5">View All Light Novels Series</Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        xl={1.75}
        sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
      ></Grid>
      {lightnovel.map((data, key) => (
        <Grid key={data.slug} xs={6} xl={1.75}>
          <Link to={viewBook("lightnovel", key)}>
            <Box>
              <img
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Link>
        </Grid>
      ))}

      <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={10}
        >
          <Box sx={{ pb: 5, textAlign: "center" }}>
            <Typography variant="h2">Other</Typography>
            <Button
              sx={{ ml: 0, mt: 2, width: "100%" }}
              variant="contained"
              size="large"
              href="/genre/sci-fi"
            >
              <Typography variant="h5">View All Other Series</Typography>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        xl={1.75}
        sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
      ></Grid>
      {other.map((data, key) => (
        <Grid key={data.slug} xs={6} xl={1.75}>
          <Link to={viewBook("other", key)}>
            <Box>
              <img
                href="/"
                src={
                  "https://storage.googleapis.com/moonquill-core.appspot.com/published-books/" +
                  data.slug +
                  ".png"
                }
                alt="image"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Catalog;

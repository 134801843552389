import React from "react";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { FaDiscord, FaFacebook, FaTwitter as FaXTwitter, FaAmazon } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 5 }}>
      <Grid
          xl={2}
          sx={{ display: { xs: "none", xl: "block" }, xlOffset: 1.75 }}
        ></Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Box sx={{ mt: -1, display: 'flex', justifyContent: 'center' }}>
            <img width={"200px"} height={"86px"} src="/img/logo-menu.png" alt="Logo" />
          </Box>
          <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
            <Link underline="none" href="https://discord.gg/s322Qvp" aria-label="Link to MoonQuill Discord">
              <Typography color="primary" variant="h5">
                <FaDiscord />
              </Typography>
            </Link>
            <Link underline="none" href="https://www.amazon.com/s?i=digital-text&rh=p_27%3AMoonQuill" aria-label="Link to MoonQuill Amazon Shop">
              <Typography color="primary" variant="h5">
                <FaAmazon />
              </Typography>
            </Link>
            <Link underline="none" href="https://twitter.com/MoonQuillNovels" aria-label="Link to MoonQuill Twitter/X">
              <Typography color="primary" variant="h5">
                <FaXTwitter />
              </Typography>
            </Link>
            <Link underline="none" href="https://www.facebook.com/MoonQuillNovels/" aria-label="Link to MoonQuill Facebook">
              <Typography color="primary" variant="h5">
                <FaFacebook />
              </Typography>
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography variant="h6">SERVICES</Typography>
          <List>
            <ListItem sx={{ pl: 0 }}>
              <Link underline="none" href="https://moonquillnovels.com/publishing-program">
                <Typography>Publishing Program</Typography>
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 0 }}>
              <Typography>Comics</Typography>
            </ListItem>
            <ListItem sx={{ pl: 0 }}>
              <Link underline="none" href="https://moonquillnovels.com/become-an-editor">
                <Typography>Become an Editor</Typography>
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography variant="h6">CONNECT</Typography>
          <List>
            <ListItem sx={{ pl: 0 }}>
              <Link underline="none" href="mailto:info@moonquill.com">
                <Typography>Contact Us</Typography>
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 0 }}>
              <Link underline="none" href="mailto:info@moonquill.com">
                <Typography>Business Inquiry</Typography>
              </Link>
            </ListItem>
            <ListItem sx={{ pl: 0 }}>
              <Link underline="none" href="mailto:dmca@moonquill.com">
                <Typography>DMCA Notification</Typography>
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Stack mt={3} pb={2} direction="row" justifyContent="center">
        <Typography variant="body">
          Copyright © 2019 - {new Date().getFullYear()}.{" "}
          <Link underline="none" href="https://moonquill.com">
            InkQuill LLC.
          </Link>{" "}
          All rights reserved.
        </Typography>
      </Stack>
    </>
  );
};

export default Footer;
